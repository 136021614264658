import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import { httpGetAdmin } from '../../services/http'

export default function ActiveUsersGraph() {
    const [history, setHistory] = useState([])

    async function fetchStats() {
        const resCurrent = await httpGetAdmin<any>('/ll-admin/active-users-history')
        setHistory(resCurrent.data.data)
    }

    useEffect(() => {
        fetchStats()
    }, [])

    return (
        <LineChart
            width={730}
            height={250}
            data={history
                .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
                .map((h) => {
                    return { date: format(new Date(h.date), 'yyyy-MM-dd'), au: Number(h.au) }
                })}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="au" stroke="#8884d8" />
        </LineChart>
    )
}
